import _ from 'lodash';

function innerGetValueByPath(obj, path) {
  if (!obj) {
    return null;
  }
  return path.split('.').reduce((o, i) => o[i], obj);
}

/**
 * Get value of an object property/path even if it's nested
 */
export function getValueByPath(obj, path) {
  const pathParts = path.split('.');
  let key = '';
  let value = null;
  for (var i = 0; i < pathParts.length; i++) {
    key += `${i !== 0 ? '.' : ''}${pathParts[i]}`;
    value = innerGetValueByPath(obj, key);
    if (!value) {
      return null;
    }
  }

  return value;
}

/**
 * Extension of indexOf method by equality function if specified
 */
export function indexOf(array, obj, fn) {
  if (!array) return -1;

  if (!fn || typeof fn !== 'function') return array.indexOf(obj);

  for (let i = 0; i < array.length; i++) {
    if (fn(array[i], obj)) {
      return i;
    }
  }

  return -1;
}

/**
 * Mobile detection
 * https://www.abeautifulsite.net/detecting-mobile-devices-with-javascript
 */
export const isMobile = {
  Android: function () {
    return (
      typeof window !== 'undefined' &&
      window.navigator.userAgent.match(/Android/i)
    );
  },
  BlackBerry: function () {
    return (
      typeof window !== 'undefined' &&
      window.navigator.userAgent.match(/BlackBerry/i)
    );
  },
  iOS: function () {
    return (
      typeof window !== 'undefined' &&
      window.navigator.userAgent.match(/iPhone|iPad|iPod/i)
    );
  },
  Opera: function () {
    return (
      typeof window !== 'undefined' &&
      window.navigator.userAgent.match(/Opera Mini/i)
    );
  },
  Windows: function () {
    return (
      typeof window !== 'undefined' &&
      window.navigator.userAgent.match(/IEMobile/i)
    );
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

export function removeElement(el) {
  if (typeof el.remove !== 'undefined') {
    el.remove();
  } else {
    el.parentNode.removeChild(el);
  }
}

/**
 * Escape regex characters
 * http://stackoverflow.com/a/6969486
 */
export function escapeRegExpChars(value) {
  if (!value) return value;

  // eslint-disable-next-line
  return value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}

export function isEmptyDateFilter(value) {
  if (!value || value === null) {
    return true;
  }

  if (typeof value === 'object') {
    return !value.startDate || !value.endDate;
  } else {
    return false;
  }
}

export function isEmptySelectValue(val) {
  return val === undefined || val === null || val === 'any' || val === '';
}

/**
 * Get the first item that pass the test
 * by second argument function
 *
 * @param {Array} list
 * @param {Function} f
 * @return {*}
 */
export function find(list, f) {
  return list.filter(f)[0];
}

/**
 * Deep copy the given object considering circular structure.
 * This function caches all nested objects and its copies.
 * If it detects circular structure, use cached copy to avoid infinite loop.
 *
 * @param {*} obj
 * @param {Array<Object>} cache
 * @return {*}
 */
export function deepCopy(obj, cache = []) {
  // just return if obj is immutable value
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  // if obj is hit, it is in circular structure
  const hit = find(cache, (c) => c.original === obj);
  if (hit) {
    return hit.copy;
  }

  const copy = Array.isArray(obj) ? [] : {};
  // put the copy into cache at first
  // because we want to refer it in recursive deepCopy
  cache.push({
    original: obj,
    copy,
  });

  Object.keys(obj).forEach((key) => {
    copy[key] = deepCopy(obj[key], cache);
  });

  return copy;
}

/**
 * forEach for object
 */
export function forEachValue(obj, fn) {
  Object.keys(obj).forEach((key) => fn(obj[key], key));
}

export function isObject(obj) {
  return obj !== null && typeof obj === 'object';
}

export function isPromise(val) {
  return val && typeof val.then === 'function';
}

export function assert(condition, msg) {
  if (!condition) throw new Error(`[vuex] ${msg}`);
}

export function replaceAll(target, search, replacement) {
  if (!target || !target.length) {
    return target;
  }
  return target.replace(
    new RegExp(escapeRegExpChars(search), 'g'),
    replacement
  );
}

export function titleCase(target) {
  if (_.isNil(target) || !_.isString(target)) {
    return target;
  } else {
    return _.startCase(target);
  }
  // return replaceAll(target, '_', ' ').toLowerCase().split(' ').map((word) => {
  //   return word.replace(word[0], word[0].toUpperCase());
  // }).join(' ');
}

export function guidGenerator() {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  );
}

export function getEnv(name, defaultValue) {
  var value = window?.configs?.[name];
  if (!_.isNil(value)) {
    if (value || value === false || value === 0) {
      return value;
    }
  }

  if (!_.isNil(process.env[name])) {
    return process.env[name];
  }

  return defaultValue;
}

export function getBooleanEnv(name, defaultValue = false) {
  let value = getEnv(name, defaultValue);
  if (_.isNil(value)) {
    return defaultValue;
  }

  if (value === true || value === 'true' || value === 1 || value === '1') {
    return true;
  }

  if (value === false || value === 'false' || value === '0' || value === 0) {
    return false;
  }

  return defaultValue;
}

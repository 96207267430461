import config from './config';
import { Promise } from 'core-js';

const client = config.client;
const baseUrl = config.baseUrl;
const vtxUrl = 'api/vtx/kb/import';

export default {
  download(path) {
    if (!path.startsWith('/')) {
      path = `/${path}`;
    }

    if (path.startsWith('/api')) {
      path = path.slice(4);
    }

    return new Promise(function (resolve, reject) {
      const uri = `${baseUrl}${path}`;
      client
        .get(uri, { responseType: 'blob' })
        .then((r) => resolve(r))
        .catch((err) => reject(err));
    });
  },
  downloadFile(id) {
    return this.download(`/files/file/${id}/downlad`);
  },
  downloadTemplate(name) {
    return this.download(`/files/templates/${name}/download`);
  },
  generateQuickLook(type, configFile, taxEngine) {
    return new Promise((resolve, reject) => {
      var formData = new FormData();
      formData.append('file', configFile);

      const uri = `${baseUrl}/${taxEngine}/quicklook?type=${type}`;

      client
        .post(uri, formData, {
          responseType: 'blob',
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((r) => resolve(r))
        .catch((err) => reject(err));
    });
  },
  importKbFile(file, uri) {
    return new Promise((resolve, reject) => {
      var formData = new FormData();
      formData.append('file', file);

      client
        .post(uri, formData, {
          responseType: 'blob',
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((r) => resolve(r))
        .catch((err) => reject(err));
    });
  },
  importDesignIntakeKB(file) {
    const uri = `${vtxUrl}/design-intake`;
    return this.importKbFile(file, uri);
  },
  importEnumTypesKB(file) {
    const uri = `${vtxUrl}/enumerations`;
    return this.importKbFile(file, uri);
  },
  importEntModelsKB(file) {
    const uri = `${vtxUrl}/enterprise-models`;
    return this.importKbFile(file, uri);
  },
  importJurisdictionKB(file) {
    const uri = `${vtxUrl}/jurisdictions`;
    return this.importKbFile(file, uri);
  },
  importJurisdictionMappingsKB(file) {
    const uri = `${vtxUrl}/jurisdiction-mappings`;
    return this.importKbFile(file, uri);
  },
  importTaxCatsKB(file) {
    const uri = `${vtxUrl}/tax-categories`;
    return this.importKbFile(file, uri);
  },
  importTaxImpositionsKB(file) {
    const uri = `${vtxUrl}/tax-impositions`;
    return this.importKbFile(file, uri);
  },
};

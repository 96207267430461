import { get, set } from './state-mutators';
import api from '@/api';

const state = {
  taskStatusTypes: [],
  clientStatusTypes: [],
  workflowStatusTypes: [],
  phaseTypes: [],
  taskTypes: [],
  systemRoles: [],
  enterpriseModels: [],
};

const getters = {
  taskStatusTypes: get('taskStatusTypes'),
  clientStatusTypes: get('clientStatusTypes'),
  workflowStatusTypes: get('workflowStatusTypes'),
  phaseTypes: get('phaseTypes'),
  taskTypes: get('taskTypes'),
  systemRoles: get('systemRoles'),
  enterpriseModels: get('enterpriseModels'),
};

const mutations = {
  taskStatusTypes: set('taskStatusTypes'),
  clientStatusTypes: set('clientStatusTypes'),
  workflowStatusTypes: set('workflowStatusTypes'),
  phaseTypes: set('phaseTypes'),
  taskTypes: set('taskTypes'),
  systemRoles: set('systemRoles'),
  enterpriseModels: set('enterpriseModels'),
};

const actions = {
  fetchEnterpriseModels({ commit }, engine) {
    return api.lists
      .enterpriseModels(engine)
      .then((models) => {
        commit('enterpriseModels', models);
      })
      .catch((err) => {
        console.error('Could not fetch Enterprise Models');
      });

    // return api.lists.all().then(lists => {
    //   commit('taskStatusTypes', lists.taskStatusTypes);
    //   commit('clientStatusTypes', lists.clientStatusTypes);
    //   commit('workflowStatusTypes', lists.workflowStatusTypes);
    //   commit('phaseTypes', lists.phaseTypes);
    //   commit('taskTypes', lists.taskTypes);
    //   commit('systemRoles', lists.systemRoles);
    // });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import { get, set } from './state-mutators';
import api from '@/api';

const state = {
  details: {
    loading: false,
    saving: false,
    detail: {},
    error: null,
  },
  search: {
    loading: false,
    items: [],
    error: null,
  },
  activities: [],
  lastUpdatedTask: null,
};

const getters = {
  loadingTaskDetails: get('details.loading'),
  savingTaskDetails: get('details.saving'),
  taskDetails: get('details.detail'),
  taskDetailsError: get('details.error'),
  searchTasksResults: get('search.items'),
  searchTasksActive: get('search.loading'),
  searchTasksError: get('search.error'),
  activities: get('activities'),
  lastUpdatedTask: get('lastUpdatedTask'),
};

const mutations = {
  loadingTaskDetails: set('details.loading'),
  savingTaskDetails: set('details.saving'),
  taskDetails: set('details.detail'),
  taskDetailsError: set('details.error'),
  searchTasksActive: set('search.loading'),
  searchTasksError: set('search.error'),
  activities: set('activities'),
  lastUpdatedTask: set('lastUpdatedTask'),
};

const actions = {
  fetchAllTasks({ commit }) {
    commit('searchTasksActive', true);
    return api.tasks
      .all()
      .then((results) => {
        let tasks = (results || []).map((o) => {
          return Object.freeze(o);
        });

        commit('activities', tasks);
        commit('searchTasksError', null);
        return tasks;
      })
      .catch((error) => {
        commit('activities', []);
        commit('searchTasksError', error);
        console.error('Could not fetch tasks');
      })
      .finally(() => {
        commit('searchTasksActive', false);
      });
  },
  handleTaskUpdate({ commit, state }, task) {
    if (task.isUserTask) {
      var fetchAll = false;
      var taskIdToRemove = null;
      if (task.status === 'PENDING' || task.status === 'SCHEDULED') {
        fetchAll = true;
      } else if (task.status === 'IN_PROGRESS') {
        // TODO: Find current username and check task.workerId === currentUsername?
        if (!task.workerId || task.workerId === '') {
          fetchAll = true;
        }
      } else {
        taskIdToRemove = task.taskId;
      }

      if (taskIdToRemove !== null) {
        var act = JSON.parse(JSON.stringify(state.activities)).filter(
          (a) => a.taskId !== taskIdToRemove
        );
        commit('activities', act);
      } else if (fetchAll && !state.search.loading) {
        var existing = state.activities.find((a) => a.taskId === task.taskId);
        if (!existing) {
          commit('searchTasksActive', true);
          api.tasks
            .all()
            .then((results) => {
              commit('activities', results);
            })
            .catch((err) => {
              console.error(
                'Errors occurred trying to refresh activities...',
                err
              );
            })
            .finally(() => {
              commit('searchTasksActive', false);
            });
        }
      }
    }
  },
  fetchTaskDetails(_ignore, id) {
    if (id instanceof Object) {
      id = id.id;
    }
    return api.tasks.details(id);
  },
  completeTask(_ignore, { task, data }) {
    task.status = 'COMPLETED';
    task.outputData = data;
    return api.tasks.submit(task);
  },
  skipTask(_ignore, task) {
    return api.tasks.skip(task);
  },
  submitFile(_ignore, { id, file }) {
    return api.tasks.submitFile(id, file);
  },
  reviewTask(_ignore, data) {
    return api.tasks.submitReview(data.taskId, data.data);
  },
  downloadFile(_ignore, fileMetaData) {
    return api.tasks.downloadFile(fileMetaData);
  },
  submitTaskData(_ignore, { taskId, data }) {
    return api.tasks.submitTaskData(taskId, data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

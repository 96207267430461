import { get, set } from './state-mutators';
import api from '@/api';

const state = {
  details: {
    saving: false,
    detail: {},
    error: null,
  },
  clients: {
    active: false,
    items: [],
    error: null,
  },
};

const getters = {
  savingClientDetails: get('details.saving'),
  clientDetailsError: get('details.error'),
  clientsResults: get('clients.items'),
  clientsRefreshing: get('clients.active'),
  clientsError: get('clients.error'),
  allClients: get('clients.items'),
};

const mutations = {
  savingDetails: set('details.saving'),
  clientDetailsError: set('details.error'),
  clientsResults: set('clients.items'),
  clientsRefreshing: set('clients.active'),
  clientsError: set('clients.error'),
  addClient(state, client) {
    var clients = JSON.parse(JSON.stringify(state.clients.items || []));
    clients.push(client);
    clients.sort((a, b) => {
      if (a.clientCode > b.clientCode) return 1;
      if (a.clientCode < b.clientCode) return -1;
      return 0;
    });
    state.clients.items = clients;
  },
  removeClient(state, client) {
    var clients = state.clients;
    var items = JSON.parse(JSON.stringify(state.clients.items || []));
    clients.items = items.filter((i) => i.clientCode !== client.clientCode);
    clients.sort((a, b) => {
      if (a.clientCode > b.clientCode) return 1;
      if (a.clientCode < b.clientCode) return -1;
      return 0;
    });
    state.clients = clients;
  },
};

const actions = {
  fetchClients({ commit }) {
    commit('clientsRefreshing', true);
    return api.clients
      .all()
      .then((results) => {
        const clients = (results || []).map((o) => {
          return Object.freeze(o);
        });

        commit('clientsResults', clients);
        commit('clientsError', null);
      })
      .catch((error) => {
        commit('clientsError', error);
        console.error('Could not fetch clients data');
      });
  },
  fetchClientDetails(_ignore, params) {
    return api.clients.details(params.name);
  },
  fetchClientOverview(_ignore, params) {
    return api.clients.overview(params.name);
  },
  fetchAssignedUsers(_ignore, params) {
    return api.clients.assignedUsers(params.name);
  },
  create({ commit }, client) {
    return new Promise((resolve, reject) => {
      api.clients
        .create(client)
        .then((res) => {
          commit('addClient', client);
          resolve(client);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  delete({ commit }, client) {
    return new Promise((resolve, reject) => {
      api.clients
        .delete(client)
        .then(() => {
          commit('removeClient', client);
          resolve(client);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  removeUserAssignment(_ignore, details) {
    return api.clients.removeUser(details);
  },
  addUserAssignment(_ignore, details) {
    return api.clients.addUser(details);
  },
  startWorkflow(_ignore, details) {
    return api.clients.startWorkflow(details);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

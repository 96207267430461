import { get, set } from './state-mutators';
import api from '@/api';

const state = {
  details: {
    loading: false,
    saving: false,
    detail: {},
    error: null,
  },
  search: {
    loading: false,
    items: [],
    error: null,
  },
  users: {
    list: [],
    loading: false,
    error: null,
  },
  roles: {
    list: [],
    loading: false,
  },
};

const getters = {
  loadingDetails: get('details.loading'),
  savingDetails: get('details.saving'),
  details: get('details.detail'),
  detailsError: get('details.error'),
  searchResults: get('search.items'),
  searchActive: get('search.loading'),
  searchError: get('search.error'),
  userList: get('users.list'),
  loadingUsers: get('users.loading'),
  usersError: get('users.error'),
  loadingRoles: get('roles.loading'),
  systemRoles: get('roles.list'),
};

const mutations = {
  loadingDetails: set('details.loading'),
  savingDetails: set('details.saving'),
  details: set('details.detail'),
  detailsError: set('details.error'),
  searchResults: set('search.items'),
  searchActive: set('search.loading'),
  searchError: set('search.error'),
  userList: set('users.list'),
  loadingUsers: set('users.loading'),
  usersError: set('users.error'),
  loadingRoles: set('roles.loading'),
  systemRoles: set('roles.list'),
};

const actions = {
  all({ commit }) {
    commit('loadingUsers', true);
    return api.users
      .getAll()
      .then((results) => {
        commit('userList', results);
        commit('usersError', null);
      })
      .catch((error) => {
        commit('usersError', error);
        console.error('Could not fetch users');
      })
      .finally(() => {
        commit('loadingUsers', false);
      });
  },
  fetchRoles({ commit }) {
    commit('loadingRoles', true);
    return api.users
      .getSystemRoles()
      .then((results) => {
        let newList = [];
        results.forEach((role) => {
          newList.push({
            label: role.displayName,
            value: role.roleName,
          });
        });
        commit('systemRoles', newList);
      })
      .catch((error) => {
        console.error('Could not fetch system roles');
      })
      .finally(() => {
        commit('loadingRoles', false);
      });
  },
  fetchUsers({ commit }, criteria) {
    commit('searchActive', true);
    return api.users
      .find(criteria)
      .then((results) => {
        commit('searchResults', results);
        commit('searchError', null);
      })
      .catch((error) => {
        commit('searchError', error);
        console.error('Could not fetch users for search');
      })
      .finally(() => {
        commit('searchActive', false);
      });
  },
  fetchUserDetails({ commit }, params) {
    commit('loadingDetails', true);
    return api.users
      .details(params.userKey)
      .then((user) => {
        commit('detailsError', null);
        return user;
      })
      .catch((error) => {
        commit('detailsError', error);
        throw error;
      })
      .finally(() => {
        commit('loadingDetails', false);
      });
  },
  createNewUser({ commit }, userData) {
    commit('savingDetails', true);
    return api.users.create(userData).finally(() => {
      commit('savingDetails', false);
    });
  },
  editUser({ commit }, { userKey, userData }) {
    commit('savingDetails', true);
    return api.users.update(userKey, userData).finally(() => {
      commit('savingDetails', false);
    });
  },
  disableUser({ commit }, user) {
    commit('savingDetails', true);
    return api.users.disable(user.userKey).finally(() => {
      commit('savingDetails', false);
    });
  },
  enableUser({ commit }, user) {
    commit('savingDetails', true);
    return api.users.enable(user.userKey).finally(() => {
      commit('savingDetails', false);
    });
  },
  deleteUser({ commit }, user) {
    commit('savingDetails', true);
    return api.users.delete(user.userKey).finally(() => {
      commit('savingDetails', false);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import { client } from '@/AxiosClient';
import { getEnv } from '@/utils/helpers';
const USE_SERVER = getEnv('VUE_APP_USE_SERVER') !== 'false';
const BASE_URL = USE_SERVER ? '/api' : '/static/data';

export default {
  useServer: USE_SERVER,
  baseUrl: BASE_URL,
  authBaseUrl: '',
  client: client,
};

'use strict';

function makeStatusIcon(status) {
  let color = null;
  let name = null;

  switch (status) {
    case 'IN_PROGRESS':
      color = 'text-info';
      name = 'fa-arrow-circle-right';
      break;
    case 'COMPLETED':
      color = 'text-success';
      name = 'fa-check';
      break;
    case 'SCHEDULED':
      color = null;
      name = 'fa-clock-o';
      break;
    case 'UNSCHEDULED':
      color = null;
      name = null;
      break;
    case 'ESCALATED':
      color = 'text-warning';
      name = 'fa-exclamation-triangle';
      break;
    case 'SKIPPED':
      color = 'text-muted';
      name = 'fa-fast-forward';
      break;
    case 'FAILED':
      color = 'text-danger';
      name = 'fa-exclamation-circle';
      break;
  }

  if (!color && !name) {
    return null;
  }

  return {
    color: color,
    name: name,
  };
}

/**
 * WorkflowTask DTO object.
 *
 * @classdesc Class representing in-progress, executed, and unscheduled tasks with methods to handle UI logic like
 * human friendly text for enumerated values, and iconography, etc.
 */
class WorkflowTask {
  /**
   * Default constructor.
   * @constructor
   */
  constructor() {
    this.workflowId = null;
    this.clientCode = null;
    this.taskId = null;

    this._taskType = null;
    this.taskDefName = null;
    this.referenceTaskName = null;
    this.correlationId = null;
    this.title = null;
    this.description = null;
    this.executed = false;
    this.reasonForIncompletion = null;
    this.startedAt = null;
    this.endedAt = null;
    this.updatedAt = null;
    this.scheduledAt = null;
    this.escalatedAt = null;
    this._status = null;
    this.workerId = null;
    this.systemTask = false;
    this.userTask = false;
    this.escalated = false;
    this.skipped = false;
    this.canSkip = false;
    this.taskRole = null;
    this.inputData = {};
    this.outputData = {};
    this._statusLabel = null;
    this._taskTypeLabel = null;
    this._statusIcon = null;
    this.user = null;
    this.inputFiles = [];
    this.outputFiles = [];
    this.phase = null;
    this.progressPct = -1;
    this.hideFromWorkflow = false;
  }

  get status() {
    return this._status;
  }

  /**
   * When a status is set, also compute the status label and icon.
   */
  set status(value) {
    this._status = (value || '').toUpperCase();
    this._statusIcon = makeStatusIcon(value);

    switch (value) {
      case 'COMPLETED':
        this._statusLabel = 'Completed';
        break;
      case 'IN_PROGRESS':
        this._statusLabel = 'In Progress';
        break;
      case 'SCHEDULED':
        this._statusLabel = 'Scheduled';
        break;
      case 'UNSCHEDULED':
        this._statusLabel = 'Unscheduled';
        break;
      case 'ESCALATED':
        this._statusLabel = 'Escalated';
        break;
      case 'FAILED':
        this._statusLabel = 'Failed';
        break;
      case 'SKIPPED':
        this._statusLabel = 'Skipped';
        break;
      default:
        this._statusLabel = value;
        break;
    }
  }

  /**
   * @returns {string} The display text of the current status.
   * @readonly
   */
  get statusLabel() {
    return this._statusLabel;
  }

  set taskType(value) {
    this._taskType = value;
    switch (value) {
      case 'FILE_UPLOAD':
        this._taskTypeLabel = 'File Upload';
        break;
      case 'FILE_GENERATE':
        this._taskTypeLabel = 'File Generate';
        break;
      case 'OUTPUT_REVIEW':
        this._taskTypeLabel = 'Review';
        break;
      case 'FORM':
        this._taskTypeLabel = 'User Input';
        break;
      case 'FILE_DIGEST':
        this._taskTypeLabel = 'File Digest';
        break;
      case 'JASPER_REPORT_GENERATE':
        this._taskTypeLabel = 'Report Generate';
        break;
      case 'FORK_JOIN':
        this._taskTypeLabel = 'Parallel Process';
        break;
      case 'JOIN':
        this._taskTypeLabel = 'Wait for Parallel Completion';
        break;
      case 'ZIP_ARCHIVE':
        this._taskTypeLabel = 'Compressed Archive Creation';
        break;
      default:
        if (this.userTask) {
          this._taskTypeLabel = 'User Task';
        } else {
          this._taskTypeLabel = 'System Task';
        }
        // this._taskTypeLabel = this.taskType;

        break;
    }
  }

  get taskType() {
    return this._taskType;
  }

  /**
   * @readonly
   */
  get taskTypeLabel() {
    return this._taskTypeLabel;
  }

  /**
   * @readonly
   */
  get statusIcon() {
    return this._statusIcon;
  }

  /**
   * @deprecated
   * @readonly
   * @alias WorkflowTask.actionText
   */
  get action() {
    return this.actionText;
  }

  /**
   * @returns {string} The full name of the user (if provided) or else the worker ID.
   * @readonly
   */
  get userFullName() {
    if (!this.userTask || !this.user) {
      return this.workerId;
    }

    let name;
    if (this.user.firstName) {
      name = this.user.firstName;
    }

    if (this.user.lastName) {
      if (name) {
        name += ` ${this.user.lastName}`;
      } else {
        name = this.user.lastName;
      }
    }

    if (!name) {
      return this.workerId;
    }

    return name;
  }

  /**
   * @returns {string} Friendly text describing the state of the task.
   * @readonly
   */
  get actionText() {
    if (this.userTask) {
      if (!this.executed && this.workerId) {
        return `Assigned to ${this.userFullName}`;
      } else if (this.workerId) {
        let pre = null;
        switch (this.taskType) {
          case 'FILE_UPLOAD':
            pre = 'Uploaded';
            break;
          case 'OUTPUT_REVIEW':
            pre = 'Reviewed';
            break;
          case 'SKIPPED':
            pre = 'Skipped';
            break;
        }
        if (pre) {
          return `${pre} by ${this.userFullName}`;
        }
      }
    }

    return this.statusLabel;
  }

  /**
   * @readonly
   * @returns {map} A map of actions that are enabled or disabled.
   */
  get enabledActions() {
    let e = {
      view: true,
      escalate: false,
      edit: false,
      rewind: false,
      resend: false,
    };

    switch (this.status) {
      case 'SKIPPED':
      case 'COMPLETED':
        e.rewind = true;
        break;
      case 'SCHEDULED':
      case 'IN_PROGRESS':
        e.escalate = true;
        e.edit = this.userTask;
        e.resend = this.systemTask;
        break;
      case 'UNSCHEDULED':
        e.view = false;
        break;
      case 'ESCALATED':
        e.rewind = this.userTask;
        break;
      case 'FAILED':
        e.escalate = true;
        break;
    }

    if (!this.userTask) {
      e.edit = false;
    }

    return e;
  }

  /**
   * Depending on the state of the task, it will return the appropriate date string.
   *
   * @readonly
   * @returns {string} The date string to use for this task.
   */
  get taskTime() {
    if (this.executed) {
      return this.endedAt || this.updatedAt;
    }
    switch (this.status) {
      case 'IN_PROGRESS':
      case 'SCHEDULED':
        return this.startedAt || this.scheduledAt || this.updatedAt;
      default:
        return this.updatedAt;
    }
  }

  get showProgress() {
    return this.progressPct >= 0;
  }

  /**
   *
   * @param {*} dto API response/DTO map to initialize a new WorkflowTask from.
   * @returns {WorkflowTask} A new WorkflowTask instance from the provided data.
   * @static
   * @public
   */
  static fromTaskDTO(dto) {
    if (!dto) {
      return null;
    }

    let t = new WorkflowTask();
    t.inputData = dto.inputData || {};
    t.outputData = dto.outputData || {};
    t.workflowId = dto.workflowId;
    t.taskId = dto.taskId;
    t.taskType = dto.taskType;
    t.taskDefName = dto.taskDefName;
    t.referenceTaskName = dto.referenceTaskName;
    t.correlationId = dto.correlationId;
    t.description =
      dto.description ||
      t.inputData.taskTitle ||
      t.inputData.taskDescription ||
      t.referenceTaskName;
    t.title = dto.title || t.description;
    t.reasonForIncompletion = dto.reasonForIncompletion;
    t.scheduledAt = dto.scheduledAt;
    t.startedAt = dto.startedAt;
    t.endedAt = dto.endedAt;
    t.updatedAt = dto.updatedAt;
    t.escalatedAt = dto.escalatedAt;
    t.workerId = dto.workerId;
    t.systemTask = dto.systemTask || false;
    t.userTask = dto.userTask || false;
    t.canSkip = dto.canSkip || dto.optional || false;
    t.skipped = dto.skipped || dto.outputData.skipped || false;
    t.escalated = dto.escalated || false;
    t.taskRole = dto.taskRole;
    t.inputFiles = dto.inputFiles || [];
    t.outputFiles = dto.outputFiles || [];
    t.user = dto.user;
    t.clientCode = dto.clientCode || t.inputData.clientCode;
    t.phase = t.inputData.phase || '--';
    t.progressPct = t.inputData.progressPct || -1;

    if (t.outputData.importResult?.hasErrors === true) {
      if (t.outputData.importResult.recordErrors?.length > 50) {
        t.outputData.importResult.recordErrors = [
          {
            message: `Due to the high volume of errors, ${
              t.outputData.importResult.recordErrors.length - 50
            } have been omitted`,
            objectName: t.outputData.importResult.recordErrors[0].objectName,
            fieldName: 'none',
            lineNumber: 1,
          },
        ].concat(t.outputData.importResult.recordErrors.slice(0, 50));
      }
    }

    if (t.inputFiles.length < 1) {
      if (t.inputData['fileLink']) {
        t.inputFiles.push({
          fileId: t.inputData.fileId,
          fileLink: t.inputData.fileLink,
          title: t.inputData.fileTitle,
          filename: t.inputData.filename,
          collection: t.inputData.fileCollection,
          category: t.inputData.fileCategory,
        });
      }

      if (t.inputData['templateLink']) {
        t.inputFiles.push({
          fileId: t.inputData.templateId,
          fileLink: t.inputData.templateLink,
          title: t.inputData.templateTitle,
          filename: t.inputData.templateFilename,
          collection: t.inputData.templateCollection,
          category: 'TEMPLATE',
        });
      }
    }

    if (!t.escalated) {
      if (
        t.outputData.taskIssue &&
        t.outputData.taskIssue.status !== 'COMPLETED'
      ) {
        t.escalated = true;
        t.escalatedAt = t.escalatedAt || t.outputData.taskIssue.registerTime;
        t.status = 'ESCALATED';
      }
    }

    if (!t.status) {
      if (t.skipped || t.outputData.skipped) {
        // Temporary patch for incorrect status report from server
        t.status = 'SKIPPED';
      }

      if (!t.status) {
        t.status = dto.status;
      }
    }

    if (!t.executed) {
      switch (dto.status) {
        case 'COMPLETED':
        case 'FAILED':
        case 'ESCALATED':
        case 'SKIPPED':
          t.executed = true;
      }
    }

    return t;
  }
}

export default WorkflowTask;

import { get, set } from './state-mutators';
import api from '@/api';

const state = {
  details: {
    loading: false,
    saving: false,
    detail: {},
    error: null,
  },
  search: {
    loading: false,
    items: [],
    error: null,
  },
  pendingTests: [],
  pendingTestColumns: [],
  testResults: [],
  testResultColumns: [],
  testResultSummary: {
    runStatus: null,
    total: 0,
    invalid: 0,
    errors: 0,
    passed: 0,
    startDate: null,
    completedDate: null,
    messages: [],
  },
  activeModels: [],
  activeScenarios: [],
};

const getters = {
  loadingTestDetails: get('details.loading'),
  savingTestDetails: get('details.saving'),
  testDetails: get('details.detail'),
  testDetailsError: get('details.error'),
  searchTestsResults: get('search.items'),
  searchTestsActive: get('search.loading'),
  searchTestsError: get('search.error'),
  pendingTests: get('pendingTests'),
  pendingTestColumns: get('pendingTestColumns'),
  testResults: get('testResults'),
  testResultColumns: get('testResultColumns'),
  testResultSummary: get('testResultSummary'),
  activeModels: get('activeModels'),
  activeScenarios: get('activeScenarios'),
};

const mutations = {
  loadingTestDetails: set('details.loading'),
  savingTestDetails: set('details.saving'),
  testDetails: set('details.detail'),
  testDetailsError: set('details.error'),
  searchTestsActive: set('search.loading'),
  searchTestsError: set('search.error'),
  pendingTests: set('pendingTests'),
  pendingTestColumns: set('pendingTestColumns'),
  testResults: set('testResults'),
  testResultColumns: set('testResultColumns'),
  testResultSummary: set('testResultSummary'),
  activeModels: set('activeModels'),
  activeScenarios: set('activeScenarios'),
};

const actions = {
  fetchPending(
    { commit },
    { clientCode, type, transactionDate, inclusion, runType }
  ) {
    commit('searchTestsActive', true);
    commit('pendingTests', []);
    return api.tests
      .pendingTests(
        clientCode,
        type,
        transactionDate,
        inclusion || 'BOTH',
        runType
      )
      .then((results) => {
        commit('pendingTests', results.transactions);
        commit('pendingTestColumns', results.activeTransactionProperties);
        return results;
      })
      .catch((error) => {
        commit('pendingTests', []);
        throw error;
      })
      .finally(() => {
        commit('searchTestsActive', false);
      });
  },
  fetchTestResults({ commit }, { clientCode, testRunId, type }) {
    commit('searchTestsActive', true);
    commit('testResults', []);
    commit('testResultSummary', {
      runStatus: null,
      total: 0,
      invalid: 0,
      errors: 0,
      passed: 0,
      startDate: null,
      completedDate: null,
      messages: [],
    });
    return api.tests
      .testResults(clientCode, testRunId, type)
      .then((results) => {
        var summary = {
          runStatus: results.runStatus,
          total: results.processedTransactionCount,
          invalid: results.invalidTransactionCount,
          errors: results.errorTransactionCount,
          startDate: new Date(results.startedAt),
          completedDate: new Date(results.completedAt),
          messages: results.messages,
          passed:
            results.processedTransactionCount -
            (results.invalidTransactionCount + results.errorTransactionCount),
        };

        summary.passedPct = summary.passed / summary.total;
        summary.invalidPct = summary.invalid / summary.total;
        summary.errorPct = summary.errors / summary.total;

        commit('activeModels', (results.distinctModelNames || []).sort());
        commit('activeScenarios', (results.distinctScenarioTypes || []).sort());
        commit('testResultSummary', summary);
        commit('testResults', results.transactions);
        commit('testResultColumns', results.activeTransactionProperties);
        return results;
      })
      .catch((error) => {
        commit('testResults', []);
        throw error;
      })
      .finally(() => {
        commit('searchTestsActive', false);
      });
  },
  fetchTestDetails(_ignore, { id, type }) {
    // TODO: Will need to use new {value,type} so it knows whether SBX/VTX
    if (id instanceof Object) {
      id = id.id;
      if (!type) {
        type = id.type;
      }
    }

    if (!type) {
      type = 'vtx';
    }

    return api.tests.details(id, type);
  },
  fetchTestResultDetails(_ignore, resultId) {
    // TODO: This check is only required until VTX test calls are migrated to the new {value,type} request instead of just resultId.
    if (resultId instanceof Object || typeof resultId === 'object') {
      const { value, type } = resultId;
      return api.tests.testResultDetails(value, type.toLowerCase());
    } else {
      return api.tests.testResultDetails(resultId);
    }
  },
  exportTestResults(_ignore, testRunId) {
    // TODO: this needs to be modified to supper the {value,type} request
    return api.tests.exportTestResults(testRunId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import config from './config';
import { Promise } from 'core-js';
import WorkflowTask from '@/WorkflowTask';
import Workflow from '@/Workflow';

const client = config.client;
const baseUrl = config.baseUrl;

function mapDataToWorkflows(data) {
  if (data) {
    return data.map((w) => {
      return Workflow.fromWorkflowDTO(w);
    });
  }

  return [];
}

export default {
  all() {
    return new Promise((resolve, reject) => {
      client
        .get(`${baseUrl}/workflow?onlyActive=false`)
        .then((r) => resolve(mapDataToWorkflows(r.data)))
        .catch((err) => reject(err));
    });
  },
  running() {
    return new Promise((resolve, reject) => {
      client
        .get(`${baseUrl}/workflow?onlyActive=true`)
        .then((r) => resolve(mapDataToWorkflows(r.data)))
        .catch((err) => reject(err));
    });
  },
  find(status) {
    return new Promise((resolve, reject) => {
      client
        .get(
          `${baseUrl}/workflow?activeOnly=${!(
            status !== 'all' || status !== false
          )}`
        )
        .then((r) => {
          resolve(mapDataToWorkflows(r.data));
        })
        .catch((err) => reject(err));
    });
  },
  details(id) {
    return new Promise(function (resolve, reject) {
      const url = `${baseUrl}/workflow/${id}`;
      client
        .get(url)
        .then((r) => {
          let data = r.data;
          if (data.tasks) {
            let mappedTasks = data.tasks.map((t) => {
              return WorkflowTask.fromTaskDTO(t);
            });
            data.tasks = mappedTasks;
            resolve(data);
          }
        })
        .catch((err) => reject(err));
    });
  },
  definitions() {
    return new Promise(function (resolve, reject) {
      client
        .get(`${baseUrl}/metadata/workflows`)
        .then((r) => {
          resolve(r.data || []);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  definition(params) {
    let workflowDefName = params.workflowDefName;
    return new Promise(function (resolve, reject) {
      client
        .get(`${baseUrl}/metadata/workflow/${workflowDefName}`)
        .then((r) => {
          resolve(r.data || {});
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  rewind(req) {
    let workflowId = req.workflowId;
    return new Promise((resolve, reject) => {
      client
        .post(`${baseUrl}/workflow/${workflowId}/rewind`, req)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  resend(req) {
    let workflowId = req.workflowId;
    return new Promise((resolve, reject) => {
      client
        .post(`${baseUrl}/workflow/${workflowId}/${req.taskId}/resend`, req)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  terminate(workflowId) {
    return new Promise((resolve, reject) => {
      client
        .delete(`/conductor/workflow/${workflowId}?reason='User Terminated'`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  delete(workflowId) {
    return new Promise((resolve, reject) => {
      client
        .delete(
          `/conductor/workflow/${workflowId}/remove?archiveWorkflow=false`
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  restart(workflowId) {
    return new Promise((resolve, reject) => {
      client
        .post(
          `/conductor/workflow/${workflowId}/restart?useLatestDefinitions=true`
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

import config from './config';
import { Promise } from 'core-js';

const client = config.client;
const baseUrl = config.baseUrl;

export default {
  all() {
    return new Promise((resolve, reject) => {
      client
        .get(`${baseUrl}/clients`)
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  details(code) {
    return new Promise((resolve, reject) => {
      client
        .get(`${baseUrl}/clients/${code}`)
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  create(c) {
    return new Promise((resolve, reject) => {
      client
        .post(`${baseUrl}/clients`, c)
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  delete(c) {
    return new Promise((resolve, reject) => {
      client
        .delete(`${baseUrl}/clients/${c.clientCode}`)
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  overview(code) {
    return new Promise((resolve, reject) => {
      client
        .get(`${baseUrl}/clients/${code}/overview`)
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  assignedUsers(code) {
    return new Promise((resolve, reject) => {
      client
        .get(`${baseUrl}/clients/${code}/users/assign`)
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  removeUser({ code, user }) {
    return new Promise((resolve, reject) => {
      client
        .delete(`${baseUrl}/clients/${code}/users/${user}`)
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  addUser({ code, user }) {
    return new Promise((resolve, reject) => {
      client
        .put(`${baseUrl}/clients/${code}/users/${user}`)
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  startWorkflow({ code, workflow }) {
    return new Promise((resolve, reject) => {
      client
        .post(`${baseUrl}/clients/${code}/workflow/${workflow}`)
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  hasSbxCompanies(code) {
    return new Promise((resolve, reject) => {
      client
        .get(`${baseUrl}/sbx/company-check?clientCode=${code}`)
        .then((r) => {
          resolve(r.data['hasCompanies'] || false);
        })
        .catch((err) => reject(err));
    });
  },
};

import store from './store';
import { keycloak } from './KeycloakClient';

const axios = require('axios').default;

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.request.use((config) => {
  config.headers.common['Authorization'] = `Bearer ${keycloak.token}`;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // Refresh our token and then retry the same request.
      return store.dispatch('auth/refreshToken').then((_) => {
        error.config.headers['Authorization'] = `Bearer ${keycloak.token}`;
        error.config.baseURL = undefined;
        // console.log('retrying request');
        return axios.request(error.config);
      });
    }
    return Promise.reject(error);
  }
);

export { axios as client };

import config from './config';
import { Promise } from 'core-js';

const client = config.client;

export default {
  pendingTests(clientCode, type, transactionDate, inclusion, runType) {
    if (!type) {
      type = 'vtx';
    } else {
      type = type.toLowerCase();
    }

    var params = {
      generated:
        inclusion === 'BOTH' || inclusion === 'GENERATED' || !inclusion,
      custom: inclusion === 'BOTH' || inclusion === 'CUSTOM' || !inclusion,
      runType,
    };

    if (transactionDate) {
      params['transactionDate'] = transactionDate;
    }

    return new Promise(function (resolve, reject) {
      const url = `${config.baseUrl}/${type}/clients/${clientCode}/test-transactions/review`;
      client
        .get(url, { params: params })
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  testResults(clientCode, runId, type) {
    if (!type) {
      type = 'vtx';
    } else {
      type = type.toLowerCase();
    }

    return new Promise(function (resolve, reject) {
      const url = `${config.baseUrl}/${type}/clients/${clientCode}/test-results/review?runId=${runId}`;
      client
        .get(url)
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  testResultDetails(resultId, type) {
    if (!type) {
      type = 'vtx';
    } else {
      type = type.toLowerCase();
    }

    return new Promise(function (resolve, reject) {
      const url = `${config.baseUrl}/${type}/testing/result/${resultId}/details`;
      client
        .get(url)
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  exportTestResults(testRunId, type) {
    if (!type) {
      type = 'vtx';
    } else {
      type = type.toLowerCase();
    }
    return new Promise(function (resolve, reject) {
      const uri = `${config.baseUrl}/${type}/testing/result/${testRunId}/download`;
      client
        .get(uri, { responseType: 'blob' })
        .then((r) => resolve(r))
        .catch((err) => reject(err));
    });
  },
  executeAdHoc(taxEngine, payload) {
    const uri = `${config.baseUrl}/${taxEngine}/testing/execute`;

    // const uri = `${config.baseUrl}/sbx/testing/CSI/import`;

    return new Promise(function (resolve, reject) {
      client
        .post(uri, payload, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
};

import { getEnv, getBooleanEnv } from '@/utils/helpers';
export const VUE_APP_IDLE_PROMPT =
  Number.parseInt(getEnv('VUE_APP_IDLE_PROMPT')) || 120000;
export const VUE_APP_KEYCLOAK_CLIENT_ID = getEnv(
  'VUE_APP_KEYCLOAK_CLIENT_ID',
  'idta-frontend'
);
export const VUE_APP_KEYCLOAK_MAX_IDLE_TIME =
  Number.parseInt(getEnv('VUE_APP_KEYCLOAK_MAX_IDLE_TIME')) || 900000;
export const VUE_APP_KEYCLOAK_REALM = getEnv(
  'VUE_APP_KEYCLOAK_REALM',
  'cordiance'
);
export const VUE_APP_KEYCLOAK_URL = getEnv(
  'VUE_APP_KEYCLOAK_URL',
  'https://id.infra.modios.io/auth'
);

export const VUE_APP_PRODUCT_MAPPER_ENABLED = getBooleanEnv(
  'VUE_APP_PRODUCT_MAPPER_ENABLED'
);

export const VUE_APP_DISTRIBUTION_VERSION = getEnv(
  'VUE_APP_DISTRIBUTION_VERSION',
  '2021.x'
);

export const VUE_APP_ALLOW_CUSTOM_ONBOARDING = getBooleanEnv(
  'VUE_APP_ALLOW_CUSTOM_ONBOARDING'
);

export const VUE_APP_ALLOW_SBX = getBooleanEnv('VUE_APP_ALLOW_SBX', true);
export const VUE_APP_ALLOW_VTX = getBooleanEnv('VUE_APP_ALLOW_VTX');
export const VUE_APP_ALLOW_AVA = getBooleanEnv('VUE_APP_ALLOW_AVA');
export const VUE_APP_ALLOW_GTD = getBooleanEnv('VUE_APP_ALLOW_GTD');

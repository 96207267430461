<template>
  <FontAwesome v-if="internalType === 'font-awesome'" v-bind="$props" />
  <CIcon v-else-if="internalType === 'core-ui'" v-bind="$props" />
</template>
<script lang="js">
import FontAwesome from './FontAwesome';

export default {
  name: 'MIcon',
  components: { FontAwesome },
  props: {
    height: {
      type: [Number, String],
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false,
      validator: (value) => {
        if (!value) {
          return true;
        }

        switch(this._.lowerCase(value)) {
          case 'font-awesome':
          case 'core-ui':
            return true;
          default:
            return false;
        }
      }
    },
  },
    data() {
      return {
        internalValue: this.name,
        internalType: this.resolveType(this.name, this.value),
      };
    },
    methods: {
      resolveType(value, type) {
        if (type) {
          switch (this._.lowerCase(type)) {
            case 'fa':
            case 'fas':
            case 'font-awesome':
              return 'font-awesome';
            case 'cil':
            case 'core-ui':
              return 'core-ui';
            default:
              console.error('Unknown icon type: ' + type);
          }
        }

        const lcValue = this._.lowerCase(value);
        if (lcValue.startsWith('fa') || lcValue === 'font-awesome') {
          return 'font-awesome';
        }

        return 'core-ui';
      },
    },
};
</script>

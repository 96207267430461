<template>
  <i :class="iconClasses" aria-hidden="true" />
</template>
<script>
export default {
  name: 'FontAwesome',
  props: {
    name: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      default: '',
      validator: (value) => {
        if (value === '') {
          return true;
        }

        if (!value || !(value.length === 5 || value.length === 2)) {
          return false;
        }

        const sizes = ['fa-lg', 'fa-2x', 'fa-3x', 'fa-4x', 'fa-5x'];
        if (value.length === 2) {
          value = 'fa-' + value;
        }

        return sizes.indexOf(value) >= 0;
      },
    },
    classes: {
      type: [String, Array, Object],
      default: null,
      required: false,
    },
    spin: {
      type: Boolean,
      default: false,
    },
    pulse: {
      type: Boolean,
      default: false,
    },
    rotate: {
      type: [String, Number],
      default: null,
      required: false,
      validator: (value) => {
        if (!value) {
          return true;
        }

        const strs = [
          'flip-horizontal',
          'flip-vertical',
          'rotate-90',
          'rotate-180',
          'rotate-270',
          '90',
          '180',
          '270',
        ];
        if (strs.indexOf(value) >= 0) {
          return true;
        }

        return value === 90 || value === 180 || value === 270;
      },
    },
  },
  computed: {
    /**
     * @returns Array
     */
    passedClasses() {
      if (!this.classes) {
        return [];
      }
      if (Array.isArray(this.classes)) {
        return this.classes;
      }
      if (typeof this.classes === 'object') {
        let arr = [];
        for (let className in this.classes) {
          if (this.classes.hasOwnProperty(className)) {
            if (this.classes[className] === true) {
              arr.push(className);
            }
          }
        }
        return arr;
      }

      return this.classes.split(',').map(function (e) {
        return e.trim();
      });
    },
    /**
     * @returns String
     */
    rotation() {
      if (!this.rotate || this.rotate === '') {
        return null;
      }
      if (!isNaN(this.rotate) && this.rotate.length > 5) {
        return this.rotate;
      }

      return `rotate-${this.rotate}`;
    },
    /**
     * @returns Object
     */
    iconClasses() {
      let sizeClass = this.size;
      let icoClass = this.name;
      let classes = this.passedClasses;
      let spinning = this.spin === true;
      let pulsing = this.pulse === true;
      let rotation = this.rotation;

      let obj = {};

      if (spinning) {
        obj['fa-spin'] = true;
      }

      if (pulsing) {
        obj['fa-pulse'] = true;
      }

      if (sizeClass) {
        obj[sizeClass] = true;
      }

      if (rotation) {
        obj[rotation] = true;
      }

      let shouldSetFa = true;
      if (classes && Array.isArray(classes)) {
        classes.forEach(function (c) {
          if (c && c !== '') {
            obj[c] = true;
            if (c === 'fas' || c === 'far') {
              shouldSetFa = false;
            }
          }
        });
      }

      if (!!icoClass && icoClass.length > 0) {
        if (icoClass.startsWith('fas ') || icoClass.startsWith('far ')) {
          shouldSetFa = false;
          icoClass = icoClass.substring(4);
        } else if (icoClass.endsWith(' fas') || icoClass.endsWith(' far')) {
          shouldSetFa = false;
          icoClass = icoClass.substring(0, icoClass.length - 4);
        }

        obj[icoClass.trim()] = true;
      }

      if (shouldSetFa) {
        obj['fa'] = true;
      }

      return obj;
    },
  },
};
</script>

import { get } from './state-mutators';
import _ from 'lodash';
import { keycloak } from '@/KeycloakClient';
import KeycloakUser from '@/classes/KeycloakUser';
import { getEnv } from '@/utils/helpers';

const KEYCLOAK_REFRESH_TTL = Number.parseInt(
  getEnv('VUE_APP_KEYCLOAK_REFRESH_TTL', '10')
);

const state = {
  user: new KeycloakUser(),
  loggedIn: false,
  status: '',
  lastActivity: 0,
  showIdlePrompt: false,
};

const getters = {
  showIdlePrompt: get('showIdlePrompt'),
  lastActivity: get('lastActivity'),
  loggedIn(state) {
    return state.loggedIn;
  },
  getStatus: get('status'),
  user(state) {
    return state.user;
  },
  isAdmin: (state) => {
    if (!state.user) {
      return false;
    }
    return !!(state.user.roles || []).find((auth) => {
      return auth == 'SYSADMIN' || auth == 'SYSOP' || auth.includes('ADMIN');
    });
  },
};

const mutations = {
  updateLastActivity: (state) => {
    state.lastActivity = new Date().getTime();
    state.showIdlePrompt = false;
  },
  showIdlePrompt: (state, show) => {
    state.showIdlePrompt = show;
  },
  requestingAuth: (state) => {
    state.status = 'LOADING';
  },
  authError: (state) => {
    state.status = 'ERROR';
  },
  setLoggedIn: (state, payload) => {
    state.user = KeycloakUser.fromParsedToken(payload);
    state.status = 'SUCCESS';
    state.loggedIn = true;
  },
  setLoggedOut: (state) => {
    state.user = new KeycloakUser();
    state.authData = '';
    state.loggedIn = false;
    state.lastActivity = 0;
    keycloak.logout();
  },
};

const actions = {
  logout: ({ commit }) => {
    commit('setLoggedOut');
  },
  refreshToken: ({ commit }) => {
    return new Promise((resolve) => {
      keycloak
        .updateToken(KEYCLOAK_REFRESH_TTL)
        .then((refreshed) => {
          if (refreshed) {
            commit('setLoggedIn', keycloak.tokenParsed);
            resolve(keycloak.token);
          } else {
            resolve(keycloak.token);
          }
        })
        .catch(() => {
          commit('setLoggedOut');
        });
    });
  },
  updateLastActivity: ({ commit }) => {
    commit('updateLastActivity');
  },
  idlePrompt: ({ commit }) => {
    commit('showIdlePrompt', true);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

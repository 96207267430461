import config from './config';
import { Promise } from 'core-js';
import { keycloak } from '@/KeycloakClient';
import EventSource from 'eventsource';

const client = config.client;
const baseUrl = config.baseUrl;

export default {
  submit(task) {
    let outputData = {
      outputData: task.outputData,
    };
    return new Promise(function (resolve, reject) {
      const url = `${baseUrl}/task/${task.taskId}`;
      client
        .post(url, outputData)
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  register() {
    return new EventSource(`${baseUrl}/notifications/register`, {
      headers: { Authorization: `Bearer ${keycloak.token}` },
    });
  },
};

import config from './config';
import { Promise } from 'core-js';

const client = config.client;
const baseUrl = config.baseUrl;

export default {
  all() {
    return new Promise(function (resolve, reject) {
      const url = `${baseUrl}/lists/`;
      client
        .get(url)
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  enterpriseModels(engine) {
    return new Promise(function (resolve, reject) {
      client
        .get(`${baseUrl}/${engine}/enterprise-models`)
        .then((r) => {
          if (engine === 'sbx') {
            resolve(
              r.data.map((o) => {
                return { ...o, name: o.modelName };
              })
            );
          } else {
            resolve(r.data);
          }
        })
        .catch((err) => reject(err));
    });
  },
};

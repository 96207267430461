import { get, set } from './state-mutators';
import api from '@/api';

const state = {
  search: {
    loading: false,
    items: [],
    error: null,
  },
  lastUpdatedWorkflow: {
    id: null,
    timestamp: 0,
  },
};

const getters = {
  searchResults: get('search.items'),
  searchActive: get('search.loading'),
  searchError: get('search.error'),
  lastUpdatedWorkflow: get('lastUpdatedWorkflow'),
};

const mutations = {
  searchResults: set('search.items'),
  searchActive: set('search.loading'),
  searchError: set('search.error'),
  lastUpdatedWorkflow(state, event) {
    state.lastUpdatedWorkflow = event;
  },
};

const actions = {
  fetchWorkflows(_ignore, status) {
    // return api.workflows.find(status === true || status !== 'all');
    if (status === true || status === 'ALL' || status === 'all') {
      return api.workflows.all();
    } else {
      return api.workflows.running();
    }
  },
  fetchWorkflowDetails(_ignore, params) {
    return api.workflows.details(params.id);
  },
  fetchWorkflowDefinitions(_ignore) {
    return api.workflows.definitions();
  },
  fetchWorkflowDefinition(_ignore, params) {
    return api.workflows.definition(params);
  },
  terminate(_ignore, id) {
    return api.workflows.terminate(id);
  },
  delete(_ignore, id) {
    return api.workflows.delete(id);
  },
  restart(_ignore, id) {
    return api.workflows.restart(id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

// Containers
const TheContainer = () => import('@/containers/TheContainer');
const TheDashboard = () => import('@/views/TheDashboard');
const TheActivityList = () => import('@/views/TheActivityList');

const TheQuickLookReport = () => import('@/views/TheQuickLookReport');

const ClientView = () => import('@/views/clients/ClientView');
const CreateClient = () => import('@/views/clients/ClientCreate');
const ClientList = () => import('@/views/clients/ClientList');
const ClientAssignUsers = () => import('@/views/clients/ClientAssignUsers');

const TheAdhocTestRunner = () => import('@/views/TheAdhocTestRunner');

const TheKnowledgebase = () => import('@/views/admin/TheKnowledgebaseUpload');

const TaskView = () => import('@/views/tasks/TaskView');
const TaskEdit = () => import('@/views/tasks/TaskEdit');
const TaskDefView = () => import('@/views/tasks/TaskDefinitionView');

const TheTestResultsReview = () => import('@/views/tests/TheTestResultsReview');

const FileList = () => import('@/views/files/FileList');

const UserList = () => import('@/views/users/UserList');

const ListWorkflows = () => import('@/views/workflows/WorkflowList');
const WorkflowOverview = () => import('@/views/workflows/WorkflowOverview');
const WorkflowDefinitionList = () =>
  import('@/views/workflows/WorkflowDefinitionList');
const WorkflowDefinitionView = () =>
  import('@/views/workflows/WorkflowDefinitionView');

const DEFAULT_TITLE = 'MODIOS';

// This is an empty pass-thru for n > 2 nested route paths.
const EmptyParentContainer = {
  render(c) {
    return c('router-view');
  },
};

Vue.use(Router);

const dashboard = {
  path: 'dashboard',
  name: 'dashboard',
  component: TheDashboard,
  meta: {
    label: 'Dashboard',
    title: 'Dashboard',
  },
};

const activities = {
  path: '/tasks',
  redirect: 'tasks/activities',
  component: EmptyParentContainer,
  meta: {
    label: 'Tasks',
  },
  children: [
    {
      path: 'activities',
      name: 'activities',
      component: TheActivityList,
      meta: {
        label: 'My Activities',
        title: 'My Activities',
      },
    },
  ],
};

const client = {
  path: '/client',
  redirect: '/',
  component: EmptyParentContainer,
  meta: {
    label: 'Clients',
  },
  children: [
    {
      path: ':name/overview',
      name: 'view-client',
      component: ClientView,
      props: true,
      meta: {
        label: 'Overview',
        title: 'Client Overview',
      },
    },
  ],
};

const utilities = {
  path: '/utilities',
  redirect: '/',
  component: EmptyParentContainer,
  meta: {
    label: 'Utilities',
  },
  children: [
    {
      path: 'reports',
      redirect: '/',
      component: EmptyParentContainer,
      meta: {
        label: 'Reports',
      },
      children: [
        {
          path: 'quick-look-report',
          name: 'quick-look-report',
          component: TheQuickLookReport,
          props: true,
          meta: {
            label: 'Rapid Insight',
            title: 'Rapid Insight',
          },
        },
        {
          path: 'adhoc-tests',
          name: 'run-adhoc-tests',
          component: TheAdhocTestRunner,
          props: true,
          meta: {
            label: 'Ad-Hoc Test Runner',
            title: 'Ad-Hoc Test Runner',
          },
        },
      ],
    },
  ],
};

const admin = {
  path: '/admin',
  redirect: '/',
  component: EmptyParentContainer,
  meta: {
    label: 'Admin',
  },
  children: [
    {
      path: 'clients/create',
      name: 'create-client',
      component: CreateClient,
      meta: {
        label: 'New Client',
        title: 'Create New Client',
      },
    },
    {
      path: 'clients',
      name: 'admin-clients',
      component: ClientList,
      props: true,
      meta: {
        label: 'Client List',
        title: 'Client List',
      },
    },
    {
      path: 'clients/:name/assign',
      name: 'admin-assign',
      component: ClientAssignUsers,
      props: true,
      meta: {
        label: 'Client User Assignments',
        title: 'Client User Assignments',
      },
    },
    {
      path: 'users',
      name: 'admin-users',
      component: UserList,
      meta: {
        label: 'All Users',
        title: 'All Users',
      },
    },
    // {
    //   path: 'user/create',
    //   name: 'admin-create-user',
    //   component: CreateUser,
    //   meta: {
    //     label: 'Create User',
    //     title: 'Create User',
    //   },
    // },
    // {
    //   path: 'user/edit/:userKey',
    //   name: 'admin-edit-user',
    //   component: EditUser,
    //   props: true,
    //   meta: {
    //     label: 'Edit User',
    //     title: 'Edit User',
    //   },
    // },
    {
      path: 'kb/upload',
      name: 'kb-upload',
      component: TheKnowledgebase,
      meta: {
        label: 'Knowledgebase',
        title: 'Knowledgebase Management',
      },
    },
  ],
};

const files = {
  path: '/files/view/category',
  redirect: '/',
  component: EmptyParentContainer,
  meta: {
    label: 'Files',
  },
  children: [
    {
      path: 'knowledgebase',
      name: 'kb-files',
      component: FileList,
      props: {
        category: 'Knowledgebase',
      },
      meta: {
        label: 'By Category',
        title: 'Knowledgebase Files',
      },
    },
    {
      path: 'admin',
      name: 'admin-files',
      component: FileList,
      props: {
        category: 'Admin',
      },
      meta: {
        label: 'By Category',
        title: 'Admin Files',
      },
    },
  ],
};

const tests = {
  path: '/tests',
  redirect: '/',
  component: EmptyParentContainer,
  meta: {
    label: 'Tests',
  },
  children: [
    {
      path: 'results/:clientCode/:testRunId',
      name: 'view-test-results',
      component: TheTestResultsReview,
      props: true,
      meta: {
        label: 'Test Results',
        title: 'Test Results Review',
      },
    },
  ],
};

const workflow = {
  path: '/workflow',
  redirect: '/workflow/running',
  component: EmptyParentContainer,
  meta: {
    label: 'Workflows',
  },
  children: [
    {
      path: ':id/overview',
      name: 'view-workflow',
      component: WorkflowOverview,
      props: true,
      meta: {
        label: 'Overview',
        title: 'Workflow Overview',
      },
    },
    {
      path: 'task/:id/view',
      name: 'view-task',
      component: TaskView,
      props: true,
      meta: {
        label: 'View Task',
        title: 'View Task',
      },
    },
    {
      path: 'task/:name/def',
      name: 'view-task-def',
      component: TaskDefView,
      props: true,
      meta: {
        label: 'View Task Definition',
        title: 'View Task Definition',
      },
    },
    {
      path: 'task/:id/edit',
      name: 'edit-task',
      component: TaskEdit,
      props: true,
      meta: {
        label: 'Edit Task',
        title: 'Edit Task',
      },
    },
    {
      path: 'definition/:workflowDefName',
      name: 'view-workflow-def',
      component: WorkflowDefinitionView,
      props: true,
      meta: {
        label: 'Definition Details',
        title: 'Workflow Definition Details',
      },
    },
    {
      path: 'definitions',
      name: 'list-workflow-definition',
      component: WorkflowDefinitionList,
      meta: {
        label: 'Definitions',
        title: 'Workflow Definitions',
      },
    },
    {
      path: 'running',
      name: 'list-running-workflows',
      component: ListWorkflows,
      props: {
        status: 'running',
      },
      meta: {
        label: 'Running',
        title: 'Running Workflows',
      },
    },
    {
      path: 'all',
      name: 'list-workflows',
      component: ListWorkflows,
      props: {
        status: 'all',
      },
      meta: {
        label: 'All',
        title: 'All Workflows',
      },
    },
  ],
};

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        dashboard,
        activities,
        client,
        workflow,
        files,
        admin,
        tests,
        utilities,
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  store.dispatch('auth/refreshToken').then(() => {
    store.dispatch('auth/updateLastActivity');
  });
  next();
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title =
      DEFAULT_TITLE + (to.meta.title ? ' - ' + to.meta.title : '');
  });
});

export default router;

import config from './config';
import { Promise } from 'core-js';

const client = config.client;
const baseUrl = config.baseUrl;

export default {
  getAll() {
    return new Promise(function (resolve, reject) {
      const url = `${baseUrl}/users/`;
      client
        .get(url)
        .then((r) => resolve(r.data.content))
        .catch((err) => reject(err));
    });
  },
  getSystemRoles() {
    return new Promise(function (resolve, reject) {
      const url = `${baseUrl}/users/roles`;
      client
        .get(url)
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  find(criteria) {
    return new Promise(function (resolve, reject) {
      // TODO: consider parsing criteria.
      const url = `${baseUrl}/users/`;
      client
        .post(url, criteria)
        .then((r) => resolve(r.data.content))
        .catch((err) => reject(err));
    });
  },
  details(userKey) {
    return new Promise(function (resolve, reject) {
      const url = `${baseUrl}/users/${userKey}`;
      client
        .get(url)
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  create(userData) {
    return new Promise(function (resolve, reject) {
      // TODO: consider parsing criteria.
      const url = `${baseUrl}/users/`;
      client
        .post(url, userData)
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  update(userKey, userData) {
    return new Promise(function (resolve, reject) {
      // TODO: consider parsing criteria.
      const url = `${baseUrl}/users/${userKey}`;
      client
        .post(url, userData)
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  disable(userKey) {
    return new Promise(function (resolve, reject) {
      const url = `${baseUrl}/users/${userKey}/disable`;
      client
        .post(url, {})
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  enable(userKey) {
    return new Promise(function (resolve, reject) {
      const url = `${baseUrl}/users/${userKey}/enable`;
      client
        .post(url, {})
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  delete(userKey) {
    return new Promise(function (resolve, reject) {
      const url = `${baseUrl}/users/${userKey}`;
      client
        .delete(url)
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
};

import Keycloak from 'keycloak-js';
import store from '@/store';
import {
  VUE_APP_KEYCLOAK_URL,
  VUE_APP_KEYCLOAK_REALM,
  VUE_APP_KEYCLOAK_CLIENT_ID,
  VUE_APP_IDLE_PROMPT,
  VUE_APP_KEYCLOAK_MAX_IDLE_TIME,
} from '@/utils/env';

const config = {
  url: VUE_APP_KEYCLOAK_URL,
  realm: VUE_APP_KEYCLOAK_REALM,
  clientId: VUE_APP_KEYCLOAK_CLIENT_ID,
  onLoad: 'login-required',
};

let keycloak = Keycloak(config);
keycloak.hasToken = () => {
  return (
    keycloak.token &&
    keycloak.idToken &&
    keycloak.token !== '' &&
    keycloak.idToken !== ''
  );
};

keycloak.onTokenExpired = () => {
  const lastActivity = store.getters['auth/lastActivity'];
  const now = new Date().getTime();
  const delta = now - lastActivity;

  if (VUE_APP_KEYCLOAK_MAX_IDLE_TIME > delta) {
    if (VUE_APP_KEYCLOAK_MAX_IDLE_TIME - delta < VUE_APP_IDLE_PROMPT) {
      store.dispatch('auth/idlePrompt');
    } else {
      store.dispatch('auth/refreshToken');
    }
  } else {
    keycloak.logout();
  }
};

export { keycloak, config };

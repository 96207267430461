<template>
  <router-view />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  computed: {
    ...mapGetters('auth', ['loggedIn'])
  },
  watch: {
    loggedIn(value) {
      if (value) {
        this.loadInitialData();
      }
    }
  },
  methods: {
    loadInitialData() {
      this.$store.dispatch('users/fetchRoles');
      this.$store.dispatch('clients/fetchClients');
      this.$store.dispatch('tasks/fetchAllTasks');
      this.setupTaskStream();
      this.listenForTaskUpdates();
    },
    setupTaskStream() {
      this.$store.dispatch('notifications/register');
    },
    listenForTaskUpdates() {
      this.$store.dispatch('notifications/addListener', {
        eventType: 'taskUpdated',
        handlerFunction: this.handleGlobalTaskUpdate
      });
      this.$store.dispatch('notifications/addListener', {
        eventType: 'taskEscalated',
        handlerFunction: this.handleGlobalTaskUpdate
      });
    },
    handleGlobalTaskUpdate(event) {
      this.$store.dispatch('tasks/handleTaskUpdate', JSON.parse(event.data));
    }
  }
};
</script>

<style lang="scss">
$fa-font-path: '~font-awesome/fonts/';
@import '~font-awesome/css/font-awesome.min.css';
// Import Main styles for this application
@import 'assets/scss/style';
</style>

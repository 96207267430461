/**
 * IDTA Server API methods and calls.
 */

import config from './config';
import clients from './clients-api';
import tasks from './tasks-api';
import users from './users-api';
import workflows from './workflows-api';
import lists from './lists-api';
import tests from './tests-api';
import files from './file-api';
import notifications from './notifications-api';
export default {
  config: config,
  clients: clients,
  lists: lists,
  tasks: tasks,
  users: users,
  workflows: workflows,
  tests: tests,
  files: files,
  notifications: notifications,
};

'use strict';
class KeycloakUser {
  /**
   * @constructor
   */
  constructor() {
    this.username = null;
    this.email = null;
    this.firstName = null;
    this.lastName = null;
    this.fullName = null;
    this.roles = [];
  }

  get displayName() {
    if (!this.fullName) {
      return this.fullName;
    }

    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    }

    return this.username;
  }

  static fromParsedToken(parsedToken) {
    let u = new KeycloakUser();
    u.email = parsedToken.email;
    u.username =
      parsedToken.preferred_username ||
      parsedToken.username ||
      parsedToken.subject;
    u.firstName = parsedToken.given_name;
    u.lastName = parsedToken.family_name;
    u.fullName = parsedToken.name;
    if (parsedToken.roles && parsedToken.roles.length > 0) {
      u.roles = parsedToken.roles;
    } else {
      u.roles = (parsedToken.realm_access || {}).roles || [];
    }

    return u;
  }
}

export default KeycloakUser;

import { get, set } from './state-mutators';
import { keycloak } from '@/KeycloakClient';
import api from '@/api';
// import Bugsnag from '@bugsnag/js';
// import { getEnv } from '@/utils/helpers';

function bugsnagNotify(e) {
  // const BUGSNAG_ENABLED = getEnv('VUE_APP_BUGSNAG_ENABLED') !== 'false';
  // if (BUGSNAG_ENABLED) {
  //   try {
  //     Bugsnag.notify(e);
  //   } catch (err) {
  //     console.error('Could not notify bugsnag', err);
  //   }
  // }
}

const state = {
  eventSource: null,
  creating: false,
};

const getters = {
  eventSource: get('eventSource'),
  creating: get('creating'),
};

const mutations = {
  eventSource: set('eventSource'),
  creating: set('creating'),
  addListener(state, { type, handler }) {
    let es = state.eventSource;
    if (es) {
      es.addEventListener(type, handler, false);
    }
    state.eventSource = es;
  },
  removeListener(state, { type, handler }) {
    let es = state.eventSource;
    if (es) {
      es.removeEventListener(type, handler, false);
    }
    state.eventSource = es;
  },
};

function createEventSource(commit) {
  console.log('Creating EventSource...');
  let es = api.notifications.register();
  es.addEventListener(
    'error',
    (event) => {
      bugsnagNotify(
        Error(
          `EventSource error - [${event.status || 'none'}] ${event.message}`
        )
      );
      if (!event.status || event.status === 500) {
        console.error('Closing SSE channel due to errors', event);
        es.close();
        commit('eventSource', null);
      } else if (event.status === 401) {
        keycloak.updateToken(600).catch((err) => {
          console.error('Could not refresh auth token for SSE channel');
          es.close();
          commit('eventSource', null);
        });
      } else {
        console.error('EventSource error', event);
      }
    },
    false
  );

  return es;
}

const actions = {
  register({ state, commit }) {
    if (state.creating) {
      console.log('EventSource is already being created...');
      return Promise((resolve) => resolve(state.es));
    }
    commit('creating', true);
    return new Promise((resolve) => {
      let es = createEventSource(commit);
      commit('eventSource', es);
      resolve(es);
    }).finally(() => {
      commit('creating', false);
    });
  },
  addListener({ state, commit }, { eventType, handlerFunction }) {
    if (!state.eventSource) {
      const message = `No EventSource, no handler will be added for '${eventType}'`;
      console.log(message);
      bugsnagNotify(Error(message));
    } else {
      commit('addListener', { type: eventType, handler: handlerFunction });
    }
  },
  removeListener({ state, commit }, { eventType, handlerFunction }) {
    if (state.eventSource) {
      commit('removeListener', { type: eventType, handler: handlerFunction });
    } else {
      const message = `No EventSource, ignoring removeListener for '${eventType}'`;
      console.log(message);
      bugsnagNotify(Error(message));
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

'use strict';

import WorkflowTask from '@/WorkflowTask';

/**
 * @class
 */
class Workflow {
  /**
   * @constructor
   */
  constructor() {
    this.workflowId = null;
    this.parentWorkflowId = null;
    this.parentWorkflowTaskId = null;
    this.workflowType = null;
    this.workflowDefName = null;
    this.version = 0;
    this.title = null;
    this.failureWorkflow = null;
    this.restartable = false;
    this.description = null;
    this.internal = false;
    this.adHoc = false;
    this._tasks = [];
    this.input = {};
    this.output = {};
    this.correlationId = null;
    this.reRunFromWorkflowId = null;
    this.reasonForIncompletion = null;
    this._status = null;
    this._statusLabel = null;
    this.createdAt = null;
    this.updatedAt = null;
    this.startedAt = null;
    this.endedAt = null;
    this.startedBy = null;
    this.updatedBy = null;
    this.clientCode = null;
    this.clientName = null;
    this.phase = null;
    this.process = null;
    this._completedTasks = 0;
    this._pendingTasks = 0;
    this._inProgressTasks = 0;
    this._failedTasks = 0;
    this._skippedTasks = 0;
    this._isEscalated = false;
  }

  get status() {
    if (
      (this._status === 'RUNNING' || this._status === 'PAUSED') &&
      this._isEscalated
    ) {
      return 'ESCALATED';
    }

    return this._status;
  }

  get isTerminal() {
    return this.status !== 'RUNNING' && this.status !== 'PAUSED';
  }

  set status(value) {
    this._status = value;
    switch (value) {
      case 'RUNNING':
        this._statusLabel = 'Running';
        break;
      case 'COMPLETED':
        this._statusLabel = 'Completed';
        break;
      case 'FAILED':
        this._statusLabel = 'Failed';
        break;
      case 'TIMED_OUT':
        this._statusLabel = 'Timed Out';
        break;
      case 'TERMINATED':
        this._statusLabel = 'Terminated';
        break;
      case 'PAUSED':
        this._statusLabel = 'Paused';
        break;
      case 'ESCALATED':
        this._statusLabel = 'Escalated';
        break;
      default:
        this._statusLabel = value;
    }
  }

  set tasks(value) {
    if (!value) {
      value = [];
    }
    this._tasks = value;
    let pending = 0;
    let inProgress = 0;
    let failed = 0;
    let completed = 0;
    let skipped = 0;

    value.forEach((t) => {
      switch (t.status) {
        case 'SCHEDULED':
          pending++;
          break;
        case 'IN_PROGRESS':
          inProgress++;
          break;
        case 'ESCALATED':
          inProgress++;
          this._isEscalated = true;
          break;
        case 'FAILED':
          failed++;
          break;
        case 'COMPLETED':
          completed++;
          break;
        case 'SKIPPED':
          skipped++;
          break;
        case 'UNSCHEDULED':
          pending++;
          break;
      }
    });

    this._completedTasks = completed;
    this._inProgressTasks = inProgress;
    this._pendingTasks = pending;
    this._failedTasks = failed;
    this._skippedTasks = skipped;
  }

  /**
   * @returns {WorkflowTask[]}
   */
  get tasks() {
    return this._tasks;
  }

  /**
   * @readonly
   */
  get completedTaskCount() {
    return (this._completedTasks || 0) + this.skippedTaskCount;
  }

  /**
   * @readonly
   */
  get inProgressTaskCount() {
    return this._inProgressTasks || 0;
  }

  /**
   * @readonly
   */
  get pendingTaskCount() {
    return this._pendingTasks || 0;
  }

  /**
   * @readonly
   */
  get failedTaskCount() {
    return this._failedTasks || 0;
  }

  /**
   * @readonly
   */
  get skippedTaskCount() {
    return this._skippedTasks || 0;
  }

  /**
   * @readonly
   */
  get statusLabel() {
    if (this._isEscalated) {
      return 'Escalated';
    }

    return this._statusLabel;
  }

  /**
   *
   * @param {*} dto
   * @returns {Workflow}
   * @static
   * @public
   */
  static fromWorkflowDTO(dto) {
    let w = new Workflow();
    w.input = dto.input || {};
    w.output = dto.output || {};
    w.workflowId = dto.workflowId;
    w.parentWorkflowId = dto.parentWorkflowId;
    w.parentWorkflowTaskId = dto.parentWorkflowTaskId;
    w.workflowType = dto.workflowType;
    w.workflowDefName = dto.workflowDefName;
    w.version = dto.version || 1;
    w.title = dto.title || w.input.workflowTitle;
    w.failureWorkflow = dto.failureWorkflow;
    w.restartable = dto.restartable || false;
    w.description = dto.description;
    w.internal = dto.internal || false;
    w.adHoc = dto.adHoc || false;
    w.correlationId = dto.correlationId;
    w.reRunFromWorkflowId = dto.reRunFromWorkflowId;
    w.reasonForIncompletion = dto.reasonForIncompletion;
    w.status = dto.status;
    w.createdAt = dto.createdAt;
    w.updatedAt = dto.updatedAt;
    w.startedAt = dto.startedAt;
    w.endedAt = dto.endedAt;
    w.startedBy = dto.startedBy || w.input.startedBy;
    w.updatedBy = dto.updatedBy;
    w.clientCode = dto.clientCode || w.input.clientCode;
    w.clientName = dto.clientName || w.input.clientName;

    w.phase = dto.phase || w.input.phase;
    w.process = dto.process || w.input.process;
    if (dto.tasks) {
      w.tasks = dto.tasks.map((t) => {
        return WorkflowTask.fromTaskDTO(t);
      });
    } else {
      w.tasks = [];
    }

    if (w.input.client) {
      if (!w.clientCode) {
        w.clientCode = w.input.client.clientCode;
      }
      if (!w.clientName) {
        w.clientName = w.input.client.clientName;
      }
    }

    if (
      w.workflowDefName === 'sbx_adhoc_test' ||
      w.workflowDefName === 'vtx_adhoc_test'
    ) {
      var runName = w.input['runName'] || w.input['name'];
      if (runName) {
        w.title = `${w.title} [${runName}]`;
      }
    }

    return w;
  }
}

export default Workflow;

import config from './config';
import { Promise } from 'core-js';
import WorkflowTask from '@/WorkflowTask';

const client = config.client;
const baseUrl = config.baseUrl;

export default {
  all() {
    return new Promise(function (resolve, reject) {
      // TODO: consider parsing criteria.
      const url = `${baseUrl}/task/`;
      client
        .get(url)
        .then((r) => {
          let data = r.data;
          if (data.length > 0) {
            let mappedTasks = data.map((t) => {
              return WorkflowTask.fromTaskDTO(t);
            });
            data = mappedTasks;
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  find(criteria) {
    return new Promise(function (resolve, reject) {
      // TODO: consider parsing criteria.
      const url = `${baseUrl}/task/`;
      client
        .get(url, criteria)
        .then((r) => {
          let data = r.data || [];
          if (data.length > 0) {
            let tasks = data.map((t) => {
              return WorkflowTask.fromTaskDTO(t);
            });
            data = tasks;
          }
          resolve(data);
        })
        .catch((err) => reject(err));
    });
  },
  details(id) {
    return new Promise(function (resolve, reject) {
      const url = `${baseUrl}/task/${id}`;
      client
        .get(url)
        .then((r) => {
          resolve(WorkflowTask.fromTaskDTO(r.data));
        })
        .catch((err) => reject(err));
    });
  },
  submit(task) {
    let outputData = {
      outputData: task.outputData,
    };
    return new Promise(function (resolve, reject) {
      const url = `${baseUrl}/task/${task.taskId}`;
      client
        .post(url, outputData)
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  submitReview(taskId, resultStatus) {
    return new Promise(function (resolve, reject) {
      client
        .post(`${baseUrl}/task/${taskId}`, {
          outputData: {
            resultStatus,
          },
        })
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  skip(task) {
    return new Promise(function (resolve, reject) {
      const url = `${baseUrl}/task/${task.taskId}/skip`;
      client
        .post(url)
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  submitFile(id, file) {
    let formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);
    formData.append('taskId', id);

    return new Promise(function (resolve, reject) {
      const url = `${baseUrl}/task/${id}/upload`;
      client
        .post(url, formData)
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  submitTaskData(taskId, data) {
    return new Promise(function (resolve, reject) {
      const url = `${baseUrl}/task/${taskId}`;
      client
        .post(url, { outputData: data })
        .then((r) => resolve(r.data))
        .catch((err) => reject(err));
    });
  },
  downloadFile(fileMetaData) {
    const fileKey = fileMetaData.file.correlationId;
    return new Promise(function (resolve, reject) {
      const uri = `${baseUrl}/files/file/${fileKey}/download`;
      client
        .get(uri, { responseType: 'blob' })
        .then((r) => resolve(r))
        .catch((err) => reject(err));
    });
  },
  setupTasksStream() {
    let es = new EventSource(`${baseUrl}/task/sse`);

    es.addEventListener(
      'taskUpdated',
      (event) => {
        console.log('got data: ', event.data);
      },
      false
    );

    es.addEventListener(
      'error',
      (event) => {
        console.log('error event received', event);
        if (event.readyState == EventSource.CLOSED) {
          console.log('Event was closed');
          console.log(EventSource);
        }
      },
      false
    );
  },
};

import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from './logger';

import coreui from './modules/coreui-module';
import clients from './modules/clients-module';
import tasks from './modules/tasks-module';
import users from './modules/users-module';
import workflows from './modules/workflows-module';
import auth from './modules/auth-module';
import lists from './modules/lists-module';
import tests from './modules/tests-module';
import notifications from './modules/notifications-module';

Vue.use(Vuex);

const debug = true;

export default new Vuex.Store({
  modules: {
    auth: auth,
    coreui: coreui,
    clients: clients,
    lists: lists,
    notifications: notifications,
    tasks: tasks,
    tests: tests,
    users: users,
    workflows: workflows,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});

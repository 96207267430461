import Vue from 'vue';
import lodash from 'lodash';
import CoreuiVue from '@coreui/vue';
import App from './App';
import router from './router/index';
import { iconsSet as icons } from './assets/icons/icons.js';
import store from './store';
import MIcon from './components/MIcon';
import VueSweetalert2 from 'vue-sweetalert2';
import VueClipboard from 'vue-clipboard2';
import { formatTimestamp, formatDate, percent } from '@/utils/format';
import { keycloak, config } from './KeycloakClient';

Vue._ = lodash;
Vue.use(CoreuiVue);
Vue.component('MIcon', MIcon);
Vue.use(VueSweetalert2);

// VueClipboard
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

// Vue.component('m-money', MMoneyWrapper);

Object.defineProperties(Vue.prototype, {
  _: {
    get() {
      return lodash;
    },
  },
  $format: {
    get() {
      return {
        formatTimestamp,
        formatDate,
        percent,
      };
    },
  },
});

const keycloakPlugin = {
  install(vue) {
    vue.$keycloak = keycloak;
    Object.defineProperties(Vue.prototype, {
      $keycloak: {
        get() {
          return keycloak;
        },
      },
    });
  },
};

Vue.use(keycloakPlugin);

Vue.$keycloak.init(config).then((authenticated) => {
  new Vue({
    el: '#app',
    router,
    store,
    //CIcon component documentation: https://coreui.io/vue/docs/components/icon
    icons,
    components: {
      App,
    },
    template: '<App/>',
  });

  if (authenticated) {
    if (keycloak.hasToken()) {
      store.commit('auth/setLoggedIn', keycloak.tokenParsed);
    } else {
      store.commit('auth/setLoggedOut');
    }
  }
});

import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

const locale2 = require('locale2');

/**
 * Formats a number for display with locale based groupings and an optional fixed amount of decimal points.
 * @param {(string|number)} num The number to format.
 * @param {number=} decimalPlaces (Optional) The fixed number of decimal places to show.
 * If not provided, num will be formatted to however many decimal places it has.
 * @returns {string} Formatted number string.
 */
export function formatNumber(num, decimalPlaces) {
  if (typeof num === 'undefined' || num === null || num === false) {
    return '';
  } else if (isNaN(num)) {
    return `${num}`;
  }

  let parsedNum = null;
  let opts = {
    style: 'decimal',
  };

  if ((decimalPlaces && decimalPlaces > 0) || decimalPlaces === 0) {
    parsedNum = parseFloat(num).toFixed(decimalPlaces);
    opts.minimumFractionDigits = decimalPlaces;
    opts.maximumFractionDigits = decimalPlaces;
  } else {
    parsedNum = Number(num);
  }

  return new Intl.NumberFormat(locale2, opts).format(parsedNum);
}

export function formatAccounting(num, decimalPlaces) {
  if (typeof num === 'undefined' || num === null || num === false) {
    return '';
  } else if (isNaN(num)) {
    return `${num}`;
  }

  let parsedNum = null;
  let opts = {
    style: 'decimal',
  };

  if ((decimalPlaces && decimalPlaces > 0) || decimalPlaces === 0) {
    parsedNum = parseFloat(num).toFixed(decimalPlaces);
    opts.minimumFractionDigits = decimalPlaces;
    opts.maximumFractionDigits = decimalPlaces;
  } else {
    parsedNum = Number(num);
  }

  let fmt = new Intl.NumberFormat(locale2, opts).format(Math.abs(parsedNum));
  if (parsedNum < 0) {
    return `(${fmt})`;
  }

  return fmt;
}

/**
 * Formats a number for display as a currency, including groupings and currency symbol.
 * @param {string} currency The currency to format the number value for.
 * @param {(string|number)} num The number to format as a currency String.
 * @returns {string} Formatted currency string.
 */
export function formatCurrency(currency, num) {
  if (!!num && typeof num === 'object') {
    return formatCurrency(num.currency || currency, num.amount);
  }

  if (!currency) {
    return '--';
  } else if (!num && num !== 0) {
    return '';
  } else if (isNaN(num)) {
    return `${num}`;
  }

  let currencyFormatter = new Intl.NumberFormat(locale2, {
    style: 'currency',
    currency: currency,
  });

  return currencyFormatter.format(num);
}

export function formatTimestamp(value) {
  return formatDate(value, 'MM/dd/yyyy HH:mm');
}
const DEFAULT_DATE_FMT = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'medium',
  timeZone: 'UTC',
});

export function formatDate(value, fmt) {
  if (!value) {
    return value;
  }

  if (fmt) {
    if (value instanceof Date) {
      return format(value, fmt);
    }
    return format(parseISO(value), fmt);
  } else {
    return DEFAULT_DATE_FMT.format(parseISO(value));
  }
}

export function percent(value) {
  return Number(value).toLocaleString(undefined, {
    style: 'percent',
    maximumFractionDigits: 0,
  });
}
export function upperCase(str) {
  if (!str) {
    return str;
  } else {
    return str.toUpperCase();
  }
}
